<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].code.label"
                        vid="code"
                        :label="beForm[rep].code.label"
                        v-model="form[rep].code"
                        placeholder="Inserisci un codice gruppo"
                        :rules="getRules('code')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapActions } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin],
  data() {
    return {
      id: null,
      repository: "registry_group",
      tableRef: "addTableDelete",
      filter: this.initFilter(),
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "customer",
          label: "Nominativo / Ragione Sociale",
          sortable: false,
        },
      ],
      form: {
        registry_group: {
          title: null,
          code: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          NAME: null,
          SURN: null,
          CNAM: null,
          NINO: null,
          CREG: null,
        },
      };
      return init;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          this.id = response.data.data.id;
          this.showConfirm({
            yesCallback: () => {
              this.shortcut(
                "registry_groups.edit",
                this.id,
                null,
                null,
                null,
                {
                  /* fromShortcut: true */
                },
                [
                  {
                    text: "Portafoglio",
                    path: "",
                    tab: "",
                    level: 0,
                    disabled: true,
                  },
                  {
                    text: "Gestione Portafoglio",
                    path: "module.PFOLIO",
                    tab: "",
                    level: 1,
                  },
                  {
                    text: "Gruppi",
                    path: "module.PFOLIO",
                    tab: "#Groups",
                    level: 2,
                  },
                ]
              );
            },
            noCallback: () => {
              this.shortcut(
                "module.PFOLIO",
                null,
                "#RegistryGroups",
                "filterRegistryGroup"
              );
            },
            title: "Gruppo creato",
            message: "Aggiungere anagrafiche?",
            yesLabel: "AGGIUNGI",
            noLabel: "NO, GRAZIE",
            yesVariant: "outline-lisaweb",
            headerBgVariant: "bg-lisaweb",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(
    .b-overlay-wrap[aria-busy="true"]
      .b-overlay
      .position-absolute
      + .position-absolute
  ) {
  transform: translateX(-50%) !important;
}
</style>
