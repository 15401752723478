var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mt-1", attrs: { "data-type": "data" } },
        [
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid, handleSubmit }) {
                  return [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "b-card",
                          {
                            attrs: {
                              header: "Dati Identificativi",
                              "header-tag": "header",
                            },
                          },
                          [
                            _c(
                              "b-card-text",
                              [
                                _c(
                                  "b-overlay",
                                  {
                                    attrs: {
                                      center: "",
                                      show: _vm.isLoading,
                                      rounded: "sm",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "overlay",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center mt-5",
                                                },
                                                [
                                                  _c("base-icon", {
                                                    attrs: {
                                                      name: "loading",
                                                      width: "35",
                                                      height: "35",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      attrs: {
                                                        id: "cancel-label",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Operazione in corso..."
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    !_vm.isLoading
                                      ? _c("div", [
                                          _vm.ok
                                            ? _c(
                                                "div",
                                                [
                                                  _c("b-row", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c("base-input", {
                                                          attrs: {
                                                            name: _vm.beForm[
                                                              _vm.rep
                                                            ].code.label,
                                                            vid: "code",
                                                            label:
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ].code.label,
                                                            placeholder:
                                                              "Inserisci un codice gruppo",
                                                            rules:
                                                              _vm.getRules(
                                                                "code"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form[_vm.rep]
                                                                .code,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form[
                                                                  _vm.rep
                                                                ],
                                                                "code",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form[rep].code",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c("base-input", {
                                                          attrs: {
                                                            name: _vm.beForm[
                                                              _vm.rep
                                                            ].title.label,
                                                            vid: "title",
                                                            label:
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ].title.label,
                                                            placeholder:
                                                              "Inserisci una descrizione",
                                                            rules:
                                                              _vm.getRules(
                                                                "title"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form[_vm.rep]
                                                                .title,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form[
                                                                  _vm.rep
                                                                ],
                                                                "title",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form[rep].title",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3 align-self-end",
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              type: "button",
                                                              variant:
                                                                "lisaweb",
                                                              size: "sm",
                                                              disabled: invalid,
                                                              title:
                                                                "Salva dati identificativi",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return handleSubmit(
                                                                  _vm.onSubmit
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        Salva\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(_vm.errorMessage) +
                                                    "\n                "
                                                ),
                                              ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "div",
            [
              _c(
                "b-col",
                { staticClass: "mb-1 pr-0", attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create",
                      attrs: {
                        type: "button",
                        variant: "primary",
                        size: "sm",
                        title: "Associa anagrafiche al gruppo",
                      },
                      on: { click: _vm.openAddRegistryModal },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "plus-circle", "aria-hidden": "true" },
                      }),
                      _vm._v(" Associa\n          anagrafiche al gruppo"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-card",
                {
                  attrs: {
                    header: "Impostazioni Anagrafiche Associate",
                    "header-tag": "header",
                  },
                },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("table-registries", {
                        ref: _vm.tableRef,
                        attrs: {
                          onlyActions: ["infomodal"],
                          noCustom: "",
                          hasChecks: "",
                          fields: _vm.editFields,
                          repository: "registry",
                          resource: "registries",
                          filterName: _vm.filterName,
                          hasChecksPaginated: true,
                          onPagination: {
                            action: "confirm",
                            checkCondition: _vm.hasChanges,
                            title: "Conferma salvataggio",
                            message:
                              "Stai per cambiare pagina. Salvare le modifiche?",
                            yesCallback: _vm.saveOnPageChange,
                            noCallback: _vm.onCancelConfirm,
                            yesLabel: "SALVA",
                            yesVariant: "lisaweb",
                            noLabel: "ANNULLA",
                          },
                          noInnerWidth: "",
                        },
                        on: {
                          interaction: _vm.onInteraction,
                          coordinator: _vm.onCoordinator,
                          communication: _vm.onCommunication,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "lisaweb",
                        size: "sm",
                        title: "Aggiorna impostazioni",
                      },
                      on: { click: _vm.onRemoveAndUpdate },
                    },
                    [_vm._v("Aggiorna")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("add-registry-modal", {
            attrs: { groupId: _vm.id },
            on: { save: _vm.onAdd },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "mt-2 float-right",
          attrs: { variant: "lisaweb", size: "sm" },
          on: {
            click: function ($event) {
              return _vm.$router.back()
            },
          },
        },
        [
          _c("b-icon-chevron-double-left", { attrs: { "font-scale": "0.9" } }),
          _vm._v("Torna indietro"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }