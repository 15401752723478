var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "addRegistryModal",
        size: "lg",
        scrollable: "",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "people" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Seleziona le Anagrafiche")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("CHIUDI")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { size: "sm", variant: "outline-lisaweb" },
                      on: {
                        click: function ($event) {
                          cancel()
                          _vm.saveEvent()
                        },
                      },
                    },
                    [_vm._v("Salva")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-button-group",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle:collapse-1",
              arg: "collapse-1",
            },
          ],
          staticClass: "my-2 filter-button-group",
          attrs: { title: _vm.getDefaultFilterMessage() },
        },
        [
          _c(
            "span",
            { staticClass: "when-open" },
            [_c("b-icon", { attrs: { icon: "funnel", "font-scale": "1.5" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "when-closed" },
            [_c("b-icon", { attrs: { icon: "funnel", "font-scale": "1.5" } })],
            1
          ),
          _vm._v("\n    Filtra\n  "),
        ]
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { visible: "", id: "collapse-1" } }, [
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onSearch(_vm.filterName)
                  },
                },
              },
              [
                _c(
                  "b-card",
                  { staticClass: "filter" },
                  [
                    _c("b-row", [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("base-input", {
                            attrs: {
                              name: "RFRNAME",
                              type: "text",
                              label: "Nominativo",
                              placeholder: "Inserisci un nominativo",
                            },
                            model: {
                              value: _vm.filter.byRegistry.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter.byRegistry, "title", $$v)
                              },
                              expression: "filter.byRegistry.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4 align-self-end" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                variant: "lisaweb",
                                size: "sm",
                              },
                            },
                            [_vm._v("Cerca")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-reset",
                              attrs: {
                                type: "button",
                                variant: "lisaweb",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClearFilter(_vm.filterName)
                                },
                              },
                            },
                            [_vm._v("Reset")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-button-group",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle:collapse-3",
                            arg: "collapse-3",
                          },
                        ],
                        staticClass: "my-2 filter-button-group",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "when-open" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "funnel", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "when-closed" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "funnel", "font-scale": "1.5" },
                            }),
                          ],
                          1
                        ),
                        _vm._v("\n            Altri Filtri\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "mt-2",
                        attrs: { id: "collapse-3" },
                        model: {
                          value: _vm.visible,
                          callback: function ($$v) {
                            _vm.visible = $$v
                          },
                          expression: "visible",
                        },
                      },
                      [
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "NINO",
                                  type: "text",
                                  label: "Codice Fiscale",
                                  placeholder: "Inserisci un codice fiscale",
                                },
                                model: {
                                  value: _vm.filter.byAttribute.NINO,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byAttribute,
                                      "NINO",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byAttribute.NINO",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "creg",
                                  type: "text",
                                  label: "Partita IVA",
                                  placeholder: "Inserisci una partita IVA",
                                },
                                model: {
                                  value: _vm.filter.byCustom.CREG.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byCustom.CREG,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byCustom.CREG.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "salesman",
                                  label: "Produttore",
                                  options: _vm.salesmen,
                                  multiple: false,
                                },
                                model: {
                                  value: _vm.filter.byBroker.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter.byBroker, "id", $$v)
                                  },
                                  expression: "filter.byBroker.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "localita",
                                  label: "Località",
                                  placeholder:
                                    "Digitare le lettere iniziali...",
                                  options: _vm.ajaxOptions,
                                  isLoading: _vm.isLoadingAjax,
                                },
                                on: { "search-change": _vm.getAjaxOptions },
                                model: {
                                  value: _vm.filter.byCorrespondence.CITY,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byCorrespondence,
                                      "CITY",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byCorrespondence.CITY",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c("base-textarea", {
                                attrs: {
                                  name: "ADDR",
                                  label: "Indirizzo",
                                  placeholder: "Inserisci un indirizzo",
                                },
                                model: {
                                  value: _vm.filter.byCustom.ADDR.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byCustom.ADDR,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byCustom.ADDR.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("table-registries", {
        ref: _vm.tableRef,
        attrs: {
          hasChecksPaginated: true,
          hasChecks: "",
          noActions: "",
          noCustom: "",
          fields: _vm.fields,
          repository: "registry",
          resource: "registries",
          filterOn: { byRelations: ["byCorrespondence"] },
          filterName: _vm.filterName,
          noInnerWidth: "",
          noInputPaginator: "",
        },
        on: { singleCheck: _vm.handlesingleCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }